import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { element } from 'prop-types';
import Home from '../views/home';
import Disclaimer from '../views/disclaimer';
import FlipBookComponent from '../views/flipbook';
// import HomeAfter from '../views/home';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
const DashboardLayout = Loadable(lazy(() => import('../layouts/dashboardLayout/DashboardLayout')));

const Preamble = Loadable(lazy(() => import('../views/preambles')));
const Constitution = Loadable(lazy(() => import('../views/constitution')));
const PreambleForm = Loadable(lazy(() => import('../views/publicParticipation')));
const PreambleFormSteps = Loadable(lazy(() => import('../views/publicParticipationSteps')));
const About = Loadable(lazy(() => import('../views/about')));
const AskBaba = Loadable(lazy(() => import('../views/askBaba')));
const AboutBaba = Loadable(lazy(() => import('../views/aboutBaba')));
const MakingConstitution = Loadable(lazy(() => import('../views/makingConstitution')));
const Authentication = Loadable(lazy(() => import('../views/authentication')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Dashboard = Loadable(lazy(() => import('../views/dashboard')));
const Events = Loadable(lazy(() => import('../views/events')));
const BlogsList = Loadable(lazy(() => import('../views/blogs/blogsList')));
const Blog = Loadable(lazy(() => import('../views/blogs/blog')));
const Policy = Loadable(lazy(() => import('../views/privacyPolicy')));
const DashboardEvents = Loadable(lazy(() => import('../views/DashboardEventForm')));
const EventReports = Loadable(lazy(() => import('../views/eventReports')));
const EventUpdate = Loadable(lazy(() => import('../views/eventUpdate')));
const HoloboxCount = Loadable(lazy(() => import('../views/holoboxCount')))
const Download = Loadable(lazy(() => import('../views/download')));
const MediaPage = Loadable(lazy(() => import('../views/mediaPage')));
const Terms = Loadable(lazy(() => import('../views/terms')));

/* ***End Layouts**** */

/* ****Pages***** */
const PageContainer = Loadable(lazy(() => import('../components/pageContainer/pageContainer')));

/* ****Routes***** */

const routes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      {
        path: '',
        element: (
          <PageContainer title="Home | Hamara Samvidhan, Hamara Swabhiman">
            <Home />
          </PageContainer>
        ),
      },
      {
        path: 'media',
        element: (
          <PageContainer title="Media Gallery | Hamara Samvidhan, Hamara Swabhiman">
            <MediaPage />
          </PageContainer>
        ),
      },
      {
        path: 'ask-baba-sahib',
        element: (
          <PageContainer title="Know your constitution | Hamara Samvidhan, Hamara Swabhiman">
            <AskBaba />
          </PageContainer>
        ),
      },
      {
        path: 'about-baba-sahib',
        element: (
          <PageContainer title="AboutBabaSahab | Hamara Samvidhan, Hamara Swabhiman">
            <AboutBaba />
          </PageContainer>
        ),
      },
      {
        path: 'preambles',
        element: (
          <PageContainer title="Preambles | Hamara Samvidhan, Hamara Swabhiman">
            <Preamble />
          </PageContainer>
        ),
      },
      {
        path: 'constitution',
        element: (
          <PageContainer title="Constitution | Hamara Samvidhan, Hamara Swabhiman">
            <Constitution />
          </PageContainer>
        ),
      },
      {
        path: 'making-of-constitution',
        element: (
          <PageContainer title="Making of Constitution | Hamara Samvidhan, Hamara Swabhiman">
            <MakingConstitution />
          </PageContainer>
        ),
      },
      {
        path: 'public-participation-steps',
        element: (
          <PageContainer title="Public Participation Steps | Hamara Samvidhan, Hamara Swabhiman">
            <PreambleFormSteps />
          </PageContainer>
        ),
      },
      {
        path: 'public-participation',
        element: (
          <PageContainer title="Public Participation | Hamara Samvidhan, Hamara Swabhiman">
            <PreambleForm />
          </PageContainer>
        ),
      },

      {
        path: 'flipBook',
        element: (
          <PageContainer title="Public Participation | Hamara Samvidhan, Hamara Swabhiman">
            <FlipBookComponent />
          </PageContainer>
        ),
      },
      {
        path: 'about',
        element: (
          <PageContainer title="About Us | Hamara Samvidhan, Hamara Swabhiman">
            <About />
          </PageContainer>
        ),
      },

      {
        path: 'download',
        element: (
          <PageContainer title="Download | Hamara Samvidhan, Hamara Swabhiman">
            <Download />
          </PageContainer>
        ),
      },
      {
        path: 'blog',
        element: <BlogsList />,
      },
      {
        path: 'blog/:id',
        element: <Blog />,
      },
      {
        path: 'privacy-policy',
        element: <Policy />,
      },
      {
        path: 'disclaimer',
        element: <Disclaimer />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'auth',
        element: <Authentication />,
        children: [
          { path: '', element: <Navigate to="register" /> },
          { path: 'register', element: <Register /> },
          { path: 'login', element: <Login /> },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: 'events', element: <Events /> },
      { path: 'event-reports', element: <EventReports /> },
      { path: 'event-update', element: <EventUpdate /> },
      { path: 'holobox', element: <HoloboxCount /> },
    ],
  },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="dashboard-events" /> },
      { path: 'dashboard-events', element: <DashboardEvents /> },
    ],
  },
];

export default routes;

import axios from 'axios';
import { getCurrentToken } from '../utils/firebaseFuntions';

const BASE_URL = 'https://asia-south1-samvidhan-75.cloudfunctions.net';

axios.defaults.baseURL = BASE_URL;

export const getSignedUrl = async (payload) => {
  try {
    const response = await axios.post('/getSignedUrl', payload);

    if (!response.data) {
      throw new Error('Failed to retrieve signed URL');
    }

    return response.data;
  } catch (error) {
    console.error('Error in getSignedUrl:', error);
    throw error;
  }
};

export const publicPartipationForm = async (data) => {
  return await axios.post('/publicMediaUpload', data);
};

export const signup = async (payload) => {
  return await axios.post('/userSignup', payload);
};

export const getOtp = async (payload) => {
  return await axios.post('/getOtp', payload);
};

export const verifyOtp = async (payload) => {
  return await axios.post('/verifyOtp', payload);
};

export const getHeatMapData = async () => {
  // return await axios.get('/getHeatMapData');
  return await axios.get(
    `getCachedResult?url=${encodeURIComponent(
      BASE_URL +
        `/getHeatMapDataV2
`,
    )}`,
  );
};

export const resetPassword = async (payload) => {
  try {
    return await axios.post(`updatePassword`, payload);
  } catch (err) {
    console.log(err);
  }
};

export const closeEvent = async (payload, isAuthenticated) => {
  try {
    let updatedPayload;
    if (isAuthenticated) {
      const token = await getCurrentToken();
      updatedPayload = {
        ...payload,
        isAuthenticated,
      };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      return await axios.post(`/closeEvent`, updatedPayload, { headers });
    } else {
      updatedPayload = {
        ...payload,
        isAuthenticated,
      };
      return await axios.post(`/closeEvent`, updatedPayload);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getPresignedUrlRead = async (foldername, filename) => {
  try {
    return await axios.post('/getSignedUrl', {
      folder: foldername,
      name: filename,
      action: 'read',
    });
  } catch (err) {
    console.log(err);
  }
};

export const saveQuestion = async (question, answer, docId) => {
  try {
    return await axios.post('/saveQuestions', {
      question,
      answer,
      docId,
    });
  } catch (err) {
    console.log(err);
  }
};

export const askBabaForm = async (payload) => {
  try {
    return await axios.post('/askAmbedkar', payload);
  } catch (e) {
    console.log(e);
  }
};

export const getLandingPageCount = async () => {
  try {
    return await axios.get(
      `getCachedResult?url=${encodeURIComponent(
        BASE_URL +
          `/getLandingCountV2
`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getEventUpdate = async () => {
  try {
    return await axios.get(
      `getCachedResult?url=${encodeURIComponent(
        BASE_URL +
          `/getDashboardDataCountV2
`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getHoloboxQuestionCount = async (params) => {
  try {
    return await axios.get(
      'https://mkfmju6q43.execute-api.ap-south-1.amazonaws.com/prod/holobox-question-count',
      { params }, // Send parameters correctly
    );
  } catch (e) {
    console.error('Error fetching Holobox question count:', e);
  }
};

export const getAudioFromElevenLabs = async (userText) => {
  try {
    const response = await axios.post(
      'https://asia-south1-samvidhan-75.cloudfunctions.net/getAudio',
      { userText },
      { responseType: 'blob' }, // Important: Expect a binary blob
    );

    const audioBlob = response.data;
    const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio
    return audioUrl;
  } catch (e) {
    console.error('Error fetching audio:', e);
    throw e;
  }
};

export const getReports = async (payload) => {
  try {
    // Construct the URL with the encoded payload as query parameters
    const queryParams = new URLSearchParams(payload).toString();
    const encodedUrl = encodeURIComponent(BASE_URL + `/getReports?${queryParams}`);

    // Fetch the cached result
    return await axios.get(`getCachedResult?url=${encodedUrl}`);
  } catch (e) {
    console.error('Error fetching reports:', e);
  }
};